import { action, observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { ModelItem } from 'app/models';
import EngagementAnalyticsModel from 'app/models/EngagementAnalyticsModel';

import Store from './Store';

export class EngagementAnalyticsStore extends Store<EngagementAnalyticsModel> {
  @observable engagementAnalytics = new ModelItem<EngagementAnalyticsModel>(
    EngagementAnalyticsModel
  );
  @observable isLoading = false;

  constructor() {
    super();
    EngagementAnalyticsModel._store = this;
  }

  @action
  async loadByOrgId(orgId: number, params: Record<string, any>): Promise<void> {
    const config = {
      url: ServerRouteHelper.api.organizations.memberEngagementAnalytics(orgId),
      params: { filters: params },
    };

    this.isLoading = true;

    try {
      const resp = await this.apiService.newGet(config);
      if (resp?.data) {
        this.engagementAnalytics.deserialize(resp.data);
      }
    } finally {
      this.isLoading = false;
    }
  }
}

export default EngagementAnalyticsStore;
