import { action, computed, observable } from 'mobx';

import { URLBuilder } from 'app/helpers/URLBuilder';
import { Model } from 'app/models/Model';
import { LOAD_METHOD, ModelContainer } from 'app/models/ModelContainer';

export class ModelItem<T extends Model, F = unknown> extends ModelContainer<T, F> {
  @observable private _item: T;

  // The cancelHandler is used to provide outside control over the cancellation of a request.
  private cancelHandler: () => void | undefined;
  public cancelPreviousRequest(): void {
    this.cancelHandler?.();
  }

  @computed
  get item(): T {
    return this._item;
  }

  @action
  public setItem(item: T) {
    this._item = item;
  }

  @action
  public deserialize(item: any) {
    const model = this.modelClass._fromJson(item) as T;
    this.setItem(model);
    this.setLoaded(true);
    this.setError(null);
  }

  @action
  public async load(
    url: URLBuilder,
    params?: { [p: string]: any },
    config?: {
      dataKey?: string;
      forceRefresh?: boolean;
      method?: LOAD_METHOD;
      itemId?: number | string;
      headers?: Record<string, any>;
      redirectIfUnauthorized?: boolean;
    }
  ) {
    const forceRefresh = config && config.forceRefresh;
    const itemId = config && config.itemId;

    const item = this.modelClass._get(itemId) as T;

    if (item && !forceRefresh) {
      this.setItem(item);
      this.setLoaded(true);
      return;
    }

    return await super.load(url, params, {
      ...config,
      cancelHandler: (handler) => (this.cancelHandler = handler),
    });
  }
}
