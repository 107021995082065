import { notification } from 'antd';
import { cloneDeep, get } from 'lodash';
import { action, observable, ObservableMap } from 'mobx';

import { ClientDataHelper } from 'app/helpers';
import ServerRouteHelper from 'app/helpers/ServerRouteHelper';
import {
  MemberItem,
  MemberModel,
  ModelItem,
  ModelList,
  PerspectiveTeammateDiffModel,
  TeamModel,
} from 'app/models';
import MemberPerspectiveResultModel from 'app/models/MemberPerspectiveResultModel';
import PerspectiveMemberModel from 'app/models/PerspectiveMemberModel';
import PerspectiveModel, { PairViewAccess } from 'app/models/PerspectiveModel';
import PerspectivePairScoreDiffModel from 'app/models/PerspectivePairScoreDiffModel';
import PerspectivePersonalReportModel from 'app/models/PerspectivePersonalReportModel';
import PerspectiveQuestionModel from 'app/models/PerspectiveQuestionModel';
import PerspectiveScoreDiffModel from 'app/models/PerspectiveScoreDiffModel';
import { TeammatesWithPerspectiveModel } from 'app/models/TeammatesWithPerspectiveModel';
import { TeamPerspectiveResultModel } from 'app/models/TeamPerspectiveResultModel';
import ToolSurveyAnswerModel from 'app/models/ToolSurveyAnswerModel';
import { PairInfo } from 'app/pages/perspective/PerspectiveReportUIStore';
import ThirdPartyService from 'app/services/ThirdPartyService';
import Store from 'app/stores/Store';

export interface PerspectiveMemberSearchMeta {
  current_page: number;
  total: number;
  last_page: number;
  page_size: number;
}

export enum PerspectiveViewTypes {
  TEAM = 'team',
  ORG = 'org',
}

export enum PerspectiveInviteSource {
  PERSPECTIVE_REPORT = 'Perspective_Report',
  TEAM_SUMMARY = 'Team_Summary',
  TEAM_DASHBOARD = 'Team_Dashboard',
  TEAMS_QUICK_ACTION = 'teams_quick_action',
  MANAGER_PLAYBOOK = 'Manager_Playbook',
  TEAM_PERSPECTIVE = 'team_perspective',
  PAIR_PERSPECTIVE = 'Pair_Perspective',
  PAIR_REPORT = 'Pair_Report',
  PERSPECTIVE_ASSESSMENT_PREVIEW_TEAM = 'perspective_assessment_preview_team',
  PERSPECTIVE_ASSESSMENT_PREVIEW_ORG = 'perspective_assessment_preview_org',
  MANAGE_TEAM_MODAL = 'manage_team_modal',
  CHECKLIST = 'checklist',
  DEFAULT_CHECKLIST = 'default_checklist',
}

export class PerspectiveStore extends Store<PerspectiveModel> {
  constructor() {
    super();
    PerspectiveModel._store = this;
  }

  @observable myPerspective = new ModelItem<MemberPerspectiveResultModel>(
    MemberPerspectiveResultModel
  );
  @observable surveyAnswers = new ModelList<ToolSurveyAnswerModel>(ToolSurveyAnswerModel);
  @observable allTeammateDiffs = new ModelList<PerspectiveTeammateDiffModel>(
    PerspectiveTeammateDiffModel
  );
  @observable membersToInvite = new ModelList<PerspectiveMemberModel>(PerspectiveMemberModel);
  @observable questions = new ModelList<PerspectiveQuestionModel>(PerspectiveQuestionModel);
  @observable teammatesWithPerspective = new ModelItem<TeammatesWithPerspectiveModel>(
    TeammatesWithPerspectiveModel
  );
  @observable inviter = new ModelItem<MemberModel>(MemberModel);
  @observable pairParticipants = new ModelList<PerspectiveMemberModel>(PerspectiveMemberModel);
  @observable pairParticipantsSearchMeta: PerspectiveMemberSearchMeta;
  @action setPairParticipantsSearchMeta = (meta: PerspectiveMemberSearchMeta) =>
    (this.pairParticipantsSearchMeta = meta);

  @observable teamNonParticipants = new ModelList<PerspectiveMemberModel>(PerspectiveMemberModel);

  @observable orgParticipants = new ModelList<PerspectiveMemberModel>(PerspectiveMemberModel);
  @observable orgParticipantsSearchMeta: PerspectiveMemberSearchMeta;

  @observable personalReport = new ModelItem<PerspectivePersonalReportModel>(
    PerspectivePersonalReportModel
  );

  @observable mbtiLetters;
  @action setLetters = (letters: string) => (this.mbtiLetters = letters);

  @observable currentMemberArchType;
  @action setCurrentMemberArchType = (archType: string) => (this.currentMemberArchType = archType);

  @observable teamPerspective: TeamPerspectiveResultModel;
  @action setTeamPerspective = (teamPerspective) => (this.teamPerspective = teamPerspective);
  @observable isLoadingTeamPerspective = false;
  @action setIsLoadingTeamPerspective = (status) => (this.isLoadingTeamPerspective = status);

  @observable pairAbsentees: PerspectiveMemberModel[];
  @action setPairAbsentees = (pairAbsentees: PerspectiveMemberModel[]) =>
    (this.pairAbsentees = pairAbsentees);

  @observable scoreDiffs: PerspectiveScoreDiffModel[];
  @action setScoreDiffs = (scoreDiffs) => (this.scoreDiffs = scoreDiffs);

  @observable isLoadingScoreDiff: boolean;
  @action setIsLoadingScoreDiff = (status) => (this.isLoadingScoreDiff = status);

  @observable isLoadingSingleScoreDiff: boolean;
  @action setIsLoadingSingleScoreDiff = (status) => (this.isLoadingSingleScoreDiff = status);

  @observable reportDefaultMemberIds: number[];
  @action setReportDefaultMemberIds = (reportDefaultMemberIds: number[]) =>
    (this.reportDefaultMemberIds = reportDefaultMemberIds);

  @observable isSendingInvites = false;
  @action toggleSendingInvites = (): void => {
    this.isSendingInvites = !this.isSendingInvites;
  };

  @observable usePerspectiveSubheadingNote = false;
  @action setUsePerspectiveSubheadingNote = (usePerspectiveSubheadingNote: boolean): void => {
    this.usePerspectiveSubheadingNote = usePerspectiveSubheadingNote;
  };

  @observable pairAccess: PairViewAccess;
  @action setPairAccess = (pairAccess: PairViewAccess): void => {
    this.pairAccess = pairAccess;
  };

  @observable multiScoreDiffs: ObservableMap<number, PerspectivePairScoreDiffModel> =
    observable.map();
  @action registerMultiScoreDiffs = (multiScoreDiffs: PerspectivePairScoreDiffModel[]): void => {
    multiScoreDiffs.forEach((diff) => this.multiScoreDiffs.set(diff.id, diff));
  };

  @observable teamPairPreviewDiffs = new ModelList<PerspectivePairScoreDiffModel>(
    PerspectivePairScoreDiffModel
  );

  @observable isLoadingPairPreviews: boolean;
  @action setIsLoadingPairPreviews = (status) => (this.isLoadingPairPreviews = status);

  @observable teamId: number;
  @action setTeamId = (teamId: number) => {
    this.teamId = teamId;
  };

  @observable pairMemberId: number;
  @action setPairMemberId = (pairMemberId: number) => {
    this.pairMemberId = pairMemberId;
  };

  @observable cachePairMember: PairInfo;
  @action setCachePairMember = (pairMember: PairInfo): PairInfo =>
    (this.cachePairMember = pairMember);

  // Keep a list of all participating members regardless of filtering
  @observable allParticipatingTeamMembers: PerspectiveMemberModel[];
  @action setAllParticipatingTeamMembers = (members: PerspectiveMemberModel[], absentees) => {
    const allMembers: PerspectiveMemberModel[] = [];

    Object.values(members).forEach((member: PerspectiveMemberModel) => {
      // skip absent members
      if (absentees.hasOwnProperty(member.id)) {
        return;
      }

      // Create a deep copy
      const copy = cloneDeep(member);
      allMembers.push(PerspectiveMemberModel.fromJson(copy));
    });

    const activeMembersIds = allMembers
      .filter((member: PerspectiveMemberModel) => member.isActive)
      .map((member: PerspectiveMemberModel) => member.id);
    this.setReportDefaultMemberIds(activeMembersIds);

    this.allParticipatingTeamMembers = allMembers;
  };

  /*
   | ---------------------------------------------------------------------------
   | Perspective non-participants search
   | ---------------------------------------------------------------------------
   |
   | Members without perspecctive
   |
   */

  @observable searchedNonParticipantsMembers = new ModelList<MemberModel>(MemberModel);
  @observable nonParticipantsSearchMeta: PerspectiveMemberSearchMeta;

  @observable currentPage;
  @action setCurrentPage = (page) => (this.currentPage = page);

  @observable colleaguesList = [];
  @action setColleaguesList = (list) => (this.colleaguesList = list);

  @observable selectedColleaguesList = [];
  @action setSelectedColleaguesList = (list) => (this.selectedColleaguesList = list);

  @action
  async doNonParticipantsSearch(search = null, pageNumber = null, pageSize = null): Promise<void> {
    const url = ServerRouteHelper.api.perspectives.nonParticipantsSearch();

    const params = {};

    // Only add page if it has a value
    if (search) {
      params['search'] = search;
    }

    if (pageSize) {
      params['page_size'] = pageSize;
    }

    if (pageNumber) {
      params['page'] = pageNumber;
    }

    // load members and get request reponse data
    const response = await this.searchedNonParticipantsMembers.load(url, params, {
      forceRefresh: true,
    });

    // stop here if no response
    if (!response || response.data.length <= 0) {
      return;
    }

    this.setColleaguesList([...this.colleaguesList, ...response.data]);
    // record meta data for paging
    const { current_page, total, last_page, per_page } = response.meta;
    this.setCurrentPage(current_page);
    this.nonParticipantsSearchMeta = {
      total,
      current_page,
      page_size: per_page,
      last_page: last_page,
    };
  }

  // ---------------------------------------------------------------------------

  @observable inviteOthersEmailsCount: number;
  @action setInviteOthersEmailsCount = (inviteOthersEmailsCount: number): void => {
    this.inviteOthersEmailsCount = inviteOthersEmailsCount;
  };

  async getSurveyAnswers() {
    const url = ServerRouteHelper.api.perspectives.report.surveyAnswers(this.sharedLinkToken);
    await this.surveyAnswers.load(url, null, { dataKey: null });
  }

  async loadAllTeammateDiffs() {
    // Don't load If there is token. since this is only for authenticated users
    if (this.sharedLinkToken) {
      return;
    }

    const url = ServerRouteHelper.api.perspectives.allTeammateDiffs();
    await this.allTeammateDiffs.load(url);
  }

  async loadMyPerspective(memberId?: number, forceApi = false): Promise<void> {
    const url = ServerRouteHelper.api.perspectives.result(memberId, this.sharedLinkToken);

    // If there's a loaded perspective for current member
    // or the loaded perspective is same from one being requested
    // then skip loading.
    const skipLoading =
      (this.myPerspective.item && !memberId) ||
      (this.myPerspective.item && memberId && this.myPerspective.item.member?.id === memberId);

    if (skipLoading && !forceApi) {
      return;
    }

    await this.myPerspective.load(url, null, {
      dataKey: null,
      headers: this.defaultHeaders,
      forceRefresh: forceApi,
    });
  }

  async loadTeamPairReportPreviews(teamId: number) {
    this.setIsLoadingPairPreviews(true);

    const url = ServerRouteHelper.api.perspectives.pairReportPreviews(teamId);
    const response = await this.apiService.newGet({ url });

    const diffs = response?.data?.diffs ?? [];
    const non_participants = response?.data?.non_participants ?? [];

    this.teamPairPreviewDiffs.setItems(diffs);
    this.teamNonParticipants.setItems(non_participants);

    this.setTeamId(response?.data?.team?.id);
    this.setIsLoadingPairPreviews(false);
  }

  async getTeamPerspective(
    teamId: number,
    filterByMemberIds: number[] = [],
    redirectIfUnauthorized = true
  ) {
    this.setIsLoadingTeamPerspective(true);
    const team = await ClientDataHelper.get('team');
    const members = await ClientDataHelper.get('members');
    const dimensions = await ClientDataHelper.get('dimensions');
    const absentees = await ClientDataHelper.get('absentees');
    const canUseClientData = get(team, 'id') === teamId && members && dimensions;

    // Only use client data if we have client data and we not filtering
    if (filterByMemberIds.length === 0 && canUseClientData) {
      this.setTeamPerspective(
        TeamPerspectiveResultModel.fromJson({
          id: `team-perspective-result-${team.id}`,
          members,
          dimensions,
          absentees,
        })
      );
      this.teamPerspective.dimensions.deserialize(dimensions);
      this.setAllParticipatingTeamMembers(members, absentees);
      this.setIsLoadingTeamPerspective(false);
      return;
    }

    try {
      const params = { only: filterByMemberIds };
      const requestUrl = ServerRouteHelper.api.perspectives.report.teamResult(
        teamId,
        this.sharedLinkToken
      );

      const response = await this.apiService.get(
        requestUrl,
        params,
        this.defaultHeaders,
        undefined,
        redirectIfUnauthorized
      );

      if (!response) {
        return;
      }

      this.setTeamPerspective(TeamPerspectiveResultModel.fromJson(response));
      this.teamPerspective.dimensions.deserialize(response.dimensions);
      this.setAllParticipatingTeamMembers(response.members, response.absentees);
    } catch (err) {
      throw err;
    } finally {
      this.setIsLoadingTeamPerspective(false);
    }
  }

  async setDefaultTeamPerspectiveFilteredMembers(teamId: number, filterByMemberIds: number[] = []) {
    const erroMsg =
      "Some of the filtered users don't belong to this team. Please make sure to add only team members of this team";
    const team = TeamModel.getOrNew(teamId);
    for (const memberId of filterByMemberIds) {
      const match = team.membersById.indexOf(memberId) !== -1;
      if (!match) {
        notification.error({
          message: 'Error',
          description: erroMsg,
          placement: 'bottomRight',
          duration: 8,
        });
        return;
      }
    }

    const requestUrl = ServerRouteHelper.api.perspectives.report.defaultFilteredMembers(teamId);

    try {
      await this.apiService.post(requestUrl, { filteredMemberIds: filterByMemberIds });
      await this.getTeamPerspective(teamId, filterByMemberIds);

      // edge case when the user select all members as default
      if (filterByMemberIds.length) {
        this.setReportDefaultMemberIds(filterByMemberIds);
      }
    } catch (err) {
      ThirdPartyService.sentry.captureException(err);

      notification.error({
        message: 'Error',
        description: erroMsg,
        placement: 'bottomRight',
        duration: 8,
      });
    }
  }

  async saveSurveyAnswer(tag: string, answer: string) {
    const url = ServerRouteHelper.api.perspectives.report.surveyAnswers(this.sharedLinkToken);
    const response = await this.apiService.post(url, { tag, answer });
    const surveyAnswer = ToolSurveyAnswerModel.fromJson(response);
    const existingAnswer = this.surveyAnswers.items.find(
      (answer) => answer.tag === surveyAnswer.tag
    );
    if (existingAnswer) {
      existingAnswer.answer = surveyAnswer.answer;
      return;
    }
    this.surveyAnswers.appendItems([surveyAnswer]);
  }

  async getScoreDiffs(teamId: number) {
    this.setIsLoadingScoreDiff(true);
    try {
      const url = ServerRouteHelper.api.perspectives.scoreDiffs(teamId, this.sharedLinkToken);
      const response = await this.apiService.get(url);
      const { diffs, absentees } = response;
      this.setScoreDiffs(diffs.map((item) => PerspectiveScoreDiffModel.fromJson(item)));
      this.setPairAbsentees(absentees.map((absentee) => PerspectiveMemberModel.fromJson(absentee)));
    } catch (err) {
    } finally {
      this.setIsLoadingScoreDiff(false);
    }
  }

  async getSingleScoreDiffs(
    memberId: number,
    teamId?: number,
    trigger?: string,
    eventSource?: string
  ) {
    if (memberId === this.cachePairMember?.memberId && teamId == this.cachePairMember?.teamId) {
      return;
    }

    this.setCachePairMember({ memberId, teamId });
    this.setIsLoadingSingleScoreDiff(true);
    try {
      await this.loadMembersScoreDiff([memberId], teamId, trigger, eventSource);
    } catch (error) {
      throw error;
    } finally {
      this.setIsLoadingSingleScoreDiff(false);
    }
  }

  async getMultiScoreDiffs(memberIds: number[]) {
    await this.loadMembersScoreDiff(memberIds);
  }

  private async loadMembersScoreDiff(
    memberIds: number[],
    teamId?: number,
    trigger?: string,
    eventSource?: string
  ) {
    const url = ServerRouteHelper.api.perspectives.multiScoreDiffs(this.sharedLinkToken);
    const config = {
      url,
      params: {
        member_ids: memberIds,
        team_id: teamId,
        trigger: trigger,
        event_source: eventSource,
      },
      headers: this.defaultHeaders,
      throwError: true,
      showGenericError: true,
    };

    const response = await this.apiService.newGet(config);

    if (!response) {
      return;
    }

    //  Gardening Debugging sc-28941
    if (!response.scoreDiffs) {
      ThirdPartyService.sentry.withScope((scope) => {
        scope.setExtra('memberIds', memberIds);
        scope.setExtra('sharedLinkToken', this.sharedLinkToken);
        scope.setExtra('responseData', response);

        ThirdPartyService.sentry.captureMessage('multiScoreDiffs empty');
      });
    }

    this.registerMultiScoreDiffs(
      response.scoreDiffs.map((diff) => {
        return PerspectivePairScoreDiffModel.fromJson(diff);
      })
    );
  }

  async getQuestions(): Promise<void> {
    const url = ServerRouteHelper.api.perspectives.questions(this.sharedLinkToken);

    await this.questions.load(url, null, {
      dataKey: 'questions',
      onResponse: (response) => {
        this.setUsePerspectiveSubheadingNote(!!response.use_perspective_subheading_note);
      },
    });
  }

  async saveAnswers(answers: Record<string, unknown>, memberId?: number, source: string = null) {
    const url = ServerRouteHelper.api.perspectives.result(memberId, this.sharedLinkToken);

    const config = {
      url,
      data: { answers, source },
      throwError: true,
      showGenericError: true,
    };

    const response = await this.apiService.newPost(config);

    if (!response) {
      return;
    }

    const currentMember = this.orgParticipants.items.find((member) => member.id === memberId);

    if (currentMember) {
      currentMember.perspective.archetype = response.archType;
    }

    this.setCurrentMemberArchType(response.archType);
    this.setLetters(response.letters);
  }

  async sendInviteToOthers(
    members: Pick<MemberItem, 'name' | 'email'>[],
    message: string,
    teamId?: number,
    source?: string
  ) {
    const url = ServerRouteHelper.api.perspectives.sendInviteToOthers(this.sharedLinkToken);
    this.toggleSendingInvites();

    const config = {
      url,
      data: { members, message, teamId, source },
      showGenericError: true,
    };

    const response = await this.apiService.newPost(config);

    if (!response) {
      this.toggleSendingInvites();
      return;
    }

    this.setInviteOthersEmailsCount(response.emails_count);
    this.toggleSendingInvites();
    return response;
  }

  async getTeammatesWithPerspective(memberId: number) {
    const url = ServerRouteHelper.api.members.teammatesWithPerspective(memberId);
    await this.teammatesWithPerspective.load(url, null, { dataKey: null });
  }

  async retake(memberId?: number) {
    const url = ServerRouteHelper.api.perspectives.retake(memberId);
    await this.apiService.get(url);

    const currentMember = this.orgParticipants.items.find((member) => member.id === memberId);

    if (currentMember) {
      currentMember.perspective.archetype = null;
    }

    this.setCurrentMemberArchType(null);
    this.myPerspective.setItem(null);
  }

  loadInviter(): Promise<void> {
    const url = ServerRouteHelper.api.perspectives.inviter(this.sharedLinkToken);
    return this.inviter.load(url);
  }

  loadPersonalReport(): Promise<void> {
    const url = ServerRouteHelper.api.perspectives.report.personal(this.sharedLinkToken);
    return this.personalReport.load(url);
  }

  /**
   * Perspective participants search
   *
   * @param search
   * @param pageSize
   * @param pageNumber
   *
   * @returns void
   */
  @action
  async doOrgParticipantsSearch(
    search: string = null,
    pageNumber: number = null,
    pageSize: number = null,
    statusSortType = null,
    forceRefresh = false
  ): Promise<void> {
    const url = ServerRouteHelper.api.perspectives.orgParticipants(this.sharedLinkToken);

    const params = {};

    // Only add page if it has a value
    if (search) {
      params['search'] = search;
    }

    if (pageSize) {
      params['page_size'] = pageSize;
    }

    if (pageNumber) {
      params['page'] = pageNumber;
    }

    if (statusSortType) {
      params['status_sort_type'] = statusSortType;
    }

    // load members and get request reponse data
    const response = await this.orgParticipants.load(url, params, { forceRefresh });

    // stop here if no response
    if (!response) {
      return;
    }

    // record meta data for paging
    const { current_page, total, per_page, last_page } = response.meta;
    this.orgParticipantsSearchMeta = {
      total,
      current_page,
      page_size: per_page,
      last_page,
    };
  }

  async loadPairParticipants(
    pairMemberId?: number,
    filter?: string,
    pageNumber?: number,
    append = false
  ): Promise<void> {
    const url = ServerRouteHelper.api.perspectives.pairParticipants(
      pairMemberId,
      filter,
      this.sharedLinkToken
    );

    const params = {};

    if (pageNumber) {
      params['page'] = pageNumber;
    }

    const response = await this.pairParticipants.load(url, params, {
      append,
      headers: this.defaultHeaders,
    });

    if (!response?.meta) {
      return;
    }

    // record meta data for paging
    const { currentPage, total, perPage, lastPage } = response?.meta;
    this.setPairParticipantsSearchMeta({
      total,
      current_page: currentPage,
      page_size: perPage,
      last_page: lastPage,
    });
  }

  async loadAllAvailableMembers(
    pairMemberId?: number,
    queryParams?: any,
    append = false,
    forceRefresh = false
  ): Promise<void> {
    const filter = queryParams?.filter ?? '';
    const url = ServerRouteHelper.api.perspectives.allAvailableMembers(
      pairMemberId,
      filter,
      this.sharedLinkToken
    );

    const params = {};

    if (queryParams?.pageNumber) {
      params['page'] = queryParams.pageNumber;
    }

    if (queryParams?.pageSize) {
      params['page_size'] = queryParams.pageSize;
    }

    if (queryParams?.statusSortType) {
      params['status_sort_type'] = queryParams.statusSortType;
    }

    if (queryParams?.organizationId) {
      params['organization_id'] = queryParams.organizationId;
    }

    if (queryParams?.teamId) {
      params['team_id'] = queryParams.teamId;
    }

    const response = await this.pairParticipants.load(url, params, {
      append,
      headers: this.defaultHeaders,
      forceRefresh,
    });

    if (!response?.meta) {
      return;
    }

    // record meta data for paging
    const { currentPage, total, perPage, lastPage } = response?.meta;
    this.setPairParticipantsSearchMeta({
      total,
      current_page: currentPage,
      page_size: perPage,
      last_page: lastPage,
    });
  }

  async ensureTeamMembership(token: string, memberId: number) {
    const url = ServerRouteHelper.api.perspectives.ensureTeamMembership(token, memberId);
    await this.apiService.post(url);
  }

  loadTeamNonParticipants(teamId: number): Promise<void> {
    const url = ServerRouteHelper.api.perspectives.teamNonParticipants(
      teamId,
      this.sharedLinkToken
    );
    return this.teamNonParticipants.load(url);
  }
}

export default PerspectiveStore;
